@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");

html{
    overflow: hidden; /* Hide scrollbars */
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: "Orbitron", sans-serif;
	font-optical-sizing: auto;
	background: #f7f7f7;
    transition: transform 0.2s ease-out;

    &.showfolders {
        transform: translateX(320px);
    }
}

a {
	cursor: pointer;
}

h1 {
	text-transform: uppercase;
	font-size: 5em;
	font-weight: bold;
}

h2 {
	text-transform: uppercase;
	font-size: 2em;
	margin-bottom: 0.5em;
}

main {

    #controls {
        position: absolute;
        top: 30px;
        left: 30px;
        width: calc(100% - 60px);
        display: flex;
        justify-content: center;
        z-index: 10; /* Ensure header is above the canvas */
        font-size: 14px;
    
        #directory {
            position: absolute;
            left: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 16px;
        }
    
        .header-counters {
            display: flex;
            gap: 40px;
    
            .header-counter {
                display: flex;
                gap: 8px;
            }
        }
    
        #time {
            position: absolute;
            right: 0;
            min-width: 100px;
            text-align: right;
            display: flex;
            gap: 8px;
        }
    }

    #fullpage {

        #flyButton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Center the button */
            padding: 10px 20px;
            font-size: 16px;
            z-index: 10; /* Ensure button is above the canvas */
            display: none;
            justify-content: center;
            align-items: center;
            gap:10px;
            flex-direction: column;
            color: black;
            background: transparent;
            border: none;
            // border-radius: 10px;
            // transition: all 0.3s ease-out;
            &.active {
                display: flex;
            }
        
            &:hover,
            &:focus {
                background-color: transparent;
                
                &:hover .folder {
                    transform: translateY(-8px);
                }
        
                &:hover .folder .paper {
                    transform: translate(-50%, 0%);
                }
        
                //there are 2 parts for the front of folder
                //one goes left and another goes right
        
                &:hover .folder .folder__front {
                    transform: skew(15deg) scaleY(0.6);
                }
        
                &:hover .folder .right {
                    transform: skew(-15deg) scaleY(0.6);
                }
        
            }
        }
    }
}

#folders {
    height: 100vh;
    background: #f0f0f0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 320px; 
    transform: translateX(-320px);  

    .folders-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 32px;
        margin-top: 32px;
        cursor: pointer;
        // transition: all 1.5s ease-out;
        // max-height: 0;
        // overflow: hidden;

        .folders-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap:8px;

            .folders-item-icon {
                .folder {
                    .folder__back {
                        width: 80px;
                        height: 60px;
                        margin-top: 7px;
                    }
                }
            }

            &:hover .folders-item-icon .folder {
                transform: translateY(-8px);
            }

            &:hover .folders-item-icon .folder .paper {
                transform: translate(-50%, 0%);
            }

            &:hover .folders-item-icon .folder .folder__front {
                transform: skew(15deg) scaleY(0.6);
            }

            &:hover .folders-item-icon .folder .right {
                transform: skew(-15deg) scaleY(0.6);
            }
        }
    }

}

#hamburger-icon {
	width: 20px;
	height: 10px;
	position: relative;
	margin: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

#hamburger-icon span {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	background: black;
	border-radius: 2px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
  }

#hamburger-icon span:nth-child(1) {
	top: 0px;
}

#hamburger-icon span:nth-child(2),
#hamburger-icon span:nth-child(3) {
	top: 4px;
}

#hamburger-icon span:nth-child(4) {
	top: 8px;
}

#hamburger-icon.open span:nth-child(1) {
	top: 4px;
	width: 0%;
	left: 50%;
}

#hamburger-icon.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#hamburger-icon.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#hamburger-icon.open span:nth-child(4) {
	top: 2px;
	width: 0%;
	left: 50%;
}

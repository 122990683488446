/* The Modal (background) */
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 20; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.65); /* Black w/ opacity */

	/* Modal Content/Box */
	.modal-content {
		position: relative;
		background-color: #fff;
		margin: 0 auto; /* 15% from the top and centered */
		padding: 1px;
		border-radius: 10px;
		width: 60%; /* Could be more or less, depending on screen size */
		overflow: hidden;
		top: 50%;
		transform: translateY(-50%);

		img {
			width:100%;
			display: block;
			border-radius: 10px;
		}

		/* Handle portrait images */
		&.portrait {
			width: auto;
			height: 80%;

			img {
				width: auto;
				height: 100%;
			}
		}

		/* The Close Button */
		.close {
			position: absolute;
			right: 10px;
			top: 10px;
			border: 1px solid white;
			border-radius: 50%;
			background-color: transparent;
			transition: all 0.3s ease-out;
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			z-index: 100;

			svg {
				width: 50%;
				height: auto;
				display: block;

				path {
					transition: all 0.3s ease-out;
					fill: white;
				}
			}

			&:hover,
			&:focus {
				background-color: white;
				svg {
					path {
						fill: black;
					}
				}
			}
		}

		video {
			display: block;
    		border-radius: 10px;
		}
	}
}
